import React from "react";
import { Route } from "react-router";
import MainPage from "../Pages/MainPage/MainPage";
import TablePage from "../Pages/TablePage/TablePage";
import TeachersPage from "../Pages/TeachersPage/TeachersPage";
import StudentsPage from "../Pages/StudentsPage/StudentsPage";
import CoursesPage from "../Pages/CoursesPage/CoursesPage";
import FeedbackPage from "../Pages/FeedbacksPage/FeedbackPage";
import TemporaryTable from "../Pages/TemporaryTable/TemporaryTable";
import ReceiptsPage from "../Pages/ReceiptsPage/ReceiptsPage";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { Dashboard } from "../Pages/DashboardPage/DashboardPage";
import SalaryPage from "../Pages/SalaryPage/SalaryPage";
import StimulationPage from "../Pages/StimulationPage/StimulationPage";
import FinancePage from "../Pages/FinancePage/FinancePage";
import SyllabusPage from "../Pages/SyllabusPage/SyllabusPage";
import DemoPage from "../Pages/DemoPage/DemoPage";

const WorkerPanelRoute = (user) => {
  const checkPage = (key) => {
    return !!user?.profiles?.find((profile) => profile.key === key);
  };

  return (
    <>
      {checkPage("attendanceTable") && (
        <>
          <Route path="/" element={<MainPage />} />
          <Route path="/student" element={<MainPage />} />
        </>
      )}
      {checkPage("dashboard") && (
        <Route path="/dashboard" element={<Dashboard />} />
      )}
      {checkPage("salary") && <Route path="/salary" element={<SalaryPage />} />}
      {checkPage("lessonTable") && (
        <Route path="/table" element={<TablePage />} />
      )}
      {checkPage("courses") && (
        <Route path="/courses" element={<CoursesPage />} />
      )}
      {checkPage("students") && (
        <Route path="/students" element={<StudentsPage />} />
      )}
      {checkPage("teachers") && (
        <Route path="/teachers" element={<TeachersPage />} />
      )}
      {checkPage("feedback") && (
        <>
          <Route path="/feedback/teacher" element={<FeedbackPage />} />
          <Route path="/feedback/student" element={<FeedbackPage />} />
          <Route path="/feedback/parent" element={<FeedbackPage />} />
        </>
      )}
      {checkPage("receipts") && (
        <Route path="receipts" element={<ReceiptsPage />} />
      )}
      {checkPage("temporaryTable") && (
        <Route path="/temporary-table" element={<TemporaryTable />} />
      )}
      {checkPage("stimulation") && (
        <>
          <Route path="/stimulations" element={<StimulationPage />} />
          <Route path="/stimulations/fine" element={<StimulationPage />} />
          <Route path="/stimulations/bonus" element={<StimulationPage />} />
        </>
      )}
      {checkPage("finance") && (
        <>
          <Route path="/finance" element={<FinancePage />} />
          <Route path="/finance/expenses" element={<FinancePage />} />
          <Route path="/finance/incomes" element={<FinancePage />} />
        </>
      )}
      {checkPage("demo") && <Route path="/demo" element={<DemoPage />} />}
      {checkPage("syllabus") && (
        <Route path="/syllabus" element={<SyllabusPage />} />
      )}
    </>
  );
};

export default WorkerPanelRoute;
